
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import Sidebar from './Component/Sidebar/Sidebar';
import Login from  './Component/Login/Login'

function App() {
  return (
    <>
    {
      !sessionStorage.getItem('login') &&  (
      <Login/>)
    }
    
    {
      sessionStorage.getItem('login') && (
        <BrowserRouter>
        <Sidebar/>
        </BrowserRouter> 
      )
    }
    </>
  );
}

export default App;
