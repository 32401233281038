import axios from 'axios'
import React, { useState,useEffect } from 'react'
import Button from '@mui/material/Button';
import { useNavigate, useParams } from 'react-router-dom';

export default function Editwapp() {

    const [name, setName] = useState('')
    const [mobile, setMobile] = useState('')
    const [detail, setDetail] = useState([])

    const navigate = useNavigate()
   

    const {id} = useParams()

    useEffect(() => {
        axios.post('https://chatbotapi.emedha.in/wapp/emedha/user/'+id)
        .then(res =>{
           setName(res.data.name)
           setMobile(res.data.mobile)
           console.log(res.data);
        })

        .catch(err =>{
          console.log('error',err)
        })
      },[id])

      const usersubmit = (e) => {
        e.preventDefault()
        axios.post('https://chatbotapi.emedha.in/wapp/emedha/edit/user',{name,mobile,sno:id})
        
            .then(res => {
                if(res.status === 200){
                    alert('user updated')
                    console.log(res.data);
                    navigate('/')
                }
                else{
                    console.error('Request failed', res.status);
                }
              })         
              .catch (err => {
                console.error('error', err);
                if (err.response) {
                    console.error('Error response data:', err.response.data);
                }
              })
    }


  return (
    <>
    <div className="container">
        <h1>Edit </h1>
    </div>
     <form onSubmit={usersubmit}>
            <div className='form d-flex gap-4'>
                
            <div className='formLeft d-flex flex-column gap-2'>
                
            <div className='d-flex flex-column' style={{marginBottom:10}}>           
            <label style={{margin:'0px 0px 14px',display:'block'}}>
                Name:
            </label>
            <input
                 type="text"
              
                
                value={name}
                onChange={(e) => {setName(e.target.value)
                console.log(e.target.value);}}
                 style={{ width: "30vw", outline: "none",padding:'0.45rem 0.75rem ' }}
                 placeholder="Enter First Name"
               />
               
            </div>
            
            <div className='d-flex flex-column'>           
            <label style={{margin:'0px 0px 14px',display:'block'}}>
                Mobile
            </label>
            <input
                 type="text"
                
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                
               
                style={{ width: "30vw", outline: "none",padding:'0.45rem 0.75rem ' }}
               />
                
            </div>
           
            </div>
            
            </div>
            <Button variant="contained" color='success' className='my-5 px-5 py-2' style={{marginTop:"10px"}} type='submit'>
              update
           </Button>
        </form>
    </>
  )
}
