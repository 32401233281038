
import React, { useState } from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';


export default function Addebot() {
   
    const [type, setType] = useState('')
    const [msg, setMsg] = useState('')
    const [footname, setFootname] = useState('')
    const [tabname, setTabname] = useState('')
    const [tempname, setTempname] = useState('')
    const [file, setFile] = useState()
    const navigate = useNavigate()

   
   

    const handleSubmit = (e) => {
        e.preventDefault();

        const data = new FormData();
        data.append('type', type);
        data.append('message', msg);
        data.append('footer_message', footname);
        data.append('table_name', tabname);
        data.append('template_name', tempname);
        data.append('photo', file);

        axios.post('https://chatbotapi.emedha.in/ebot/emedha/data/details', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((res) => {
            console.log('Data uploaded successfully');
            console.log(res.data);
            navigate('/ebot')
        })
        .catch((error) => {
            console.error('Error:', error);
            
        });
    };

    return (
       <>
        <div className="container">
        <h1>ADD Data </h1>
    </div>
     <form onSubmit={handleSubmit}>
            <div className='form d-flex gap-4'>
                
            <div className='formLeft d-flex flex-column gap-2'>
                
            <div className='d-flex flex-column' style={{marginBottom:10}}>           
            <label style={{margin:'0px 0px 14px',display:'block'}}>
                type:
            </label>
            <input
                 type="text"
                onChange={(e) => {setType(e.target.value)
                }}
                 style={{ width: "30vw", outline: "none",padding:'0.45rem 0.75rem ' }}
                 placeholder="Enter type Name"
               />
               
            </div>
            
            <div className='d-flex flex-column'>           
            <label style={{margin:'0px 0px 14px',display:'block'}}>
                message
            </label>
            <input
                 type="text"
                onChange={(e) => setMsg(e.target.value)}
                placeholder='Enter message'
                style={{ width: "30vw", outline: "none",padding:'0.45rem 0.75rem ' }}
               />
                
            </div>

            <div className='d-flex flex-column' style={{marginBottom:10}}>           
            <label style={{margin:'0px 0px 14px',display:'block'}}>
                Footer message :
            </label>
            <input
                 type="text"
                onChange={(e) => {setFootname(e.target.value)
                }}
                 style={{ width: "30vw", outline: "none",padding:'0.45rem 0.75rem ' }}
                 placeholder="Enter footer name "
               />
               
            </div>
            
            <div className='d-flex flex-column'>           
            <label style={{margin:'0px 0px 14px',display:'block'}}>
                Table name
            </label>
            <input
                 type="text"
                onChange={(e) => setTabname(e.target.value)}
                placeholder='Enter table name'
                style={{ width: "30vw", outline: "none",padding:'0.45rem 0.75rem ' }}
               />
                
            </div>

            <div className='d-flex flex-column' style={{marginBottom:10}}>           
            <label style={{margin:'0px 0px 14px',display:'block'}}>
                Template name:
            </label>
            <input
                 type="text"
                onChange={(e) => {setTempname(e.target.value)
                }}
                 style={{ width: "30vw", outline: "none",padding:'0.45rem 0.75rem ' }}
                 placeholder="Enter template Name"
               />
               
            </div>
            
            <div className='d-flex flex-column mb-5'>           
            {/* <label style={{margin:'0px 0px 14px',display:'block'}}>
                image
            </label> */}
            <input
                 type="file"
                onChange={(e) => setFile(e.target.files[0])}
               
                style={{ width: "30vw", outline: "none",padding:'0.45rem 0.75rem ' }}
               />
                
            </div>
           
            </div>
            
            </div>
            <Button variant="contained" color='success' className='my-5 px-5 py-2' style={{marginTop:"10px"}} type='submit'>
              submit
           </Button>
        </form>
       </>
    );
}
