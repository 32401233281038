import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';

function createData(id, name, calories, fat, carbs, protein) {
  return {
    id,
    name,
    calories,
    fat,
    carbs,
    protein,
  };
}

const rows = [
 
];


export default function Wappmessage() {
  const [page, setPage] = React.useState(0);
  
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

   const navigate =  useNavigate()

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(+event.target.value));
    setPage(0);
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    axios.post('https://chatbotapi.emedha.in/wapp/emedha/get-all')
    .then((res => {
      console.log("WAPP MESSAGES", res.data.data);
      setData(res.data.data)
    }))
    .catch((err => {
      console.error(err)
    }))
  },[])

  const handledelete = (id) => {
    axios.post(`https://chatbotapi.emedha.in/wapp/emedha/del/${id}`)
    .then((res => {
      console.log(res.data)
      alert('user deleted')
      setData(prevData => prevData.filter((item => item.id != id)))
    }))
    .catch((err) => {
      console.error('Error',err)
    })
  }

  const handlereset = () => {
    axios.post('https://chatbotapi.emedha.in/wapp/emedha/wapp-reset')
    .then((res => {
      console.log(res.data)
      alert('Successfully  reseted')
   
    }))
    .catch((err) => {
      console.error('Error',err)
    })
  }

  return (
    <div>
      <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
          
          <TableContainer>
      
      <div style={{margin:30}} >
      <Button variant="outlined">Total Records : {data.length}</Button>
      <Button variant="outlined" endIcon={<AddIcon />} style={{marginLeft:20}} onClick={() => {navigate('/wappadd')}}>
        ADD
      </Button>
      <Button variant="outlined" style={{marginLeft:20}} onClick={handlereset}>
        RESET
      </Button>
      </div>
      
   <Table >
      
       <TableHead sx={{ backgroundColor: 'lightgray' }}>
         <TableRow>
           <TableCell
             
             style={{ top: 57, minWidth: 30, fontWeight: 'bold', fontSize: 12 }}
           >
             Sno
           </TableCell>
           <TableCell
            
             style={{ top: 57, minWidth: 30, fontWeight: 'bold', fontSize: 12 }}
           >
             Name
           </TableCell>
           <TableCell

             
             style={{ top: 57, minWidth: 87, fontWeight: 'bold', fontSize: 12 }}
           >
             Mobile number
           </TableCell>
           <TableCell

            
             style={{ top: 57, minWidth: 87, fontWeight: 'bold', fontSize: 12 }}
           >
             Action
           </TableCell>
         </TableRow>
       </TableHead>
       <TableBody>        
      {
        data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item => (
          <TableRow key={item.sno} tabIndex={-1} >
                 <TableCell 
                   style={{ top: 57, minWidth: 30, fontSize: 12 }}>{item.sno}</TableCell>
                 <TableCell 
                   style={{ top: 57, minWidth: 30, fontSize: 12 }}>{item.name}</TableCell>
                 <TableCell 
                   style={{ top: 57, minWidth: 87, fontSize: 12 }}>{item.mobile}</TableCell>
                 <TableCell 
                   style={{ top: 57, minWidth: 87, fontSize: 12,display:'flex'}}>
                    <div onClick={() => navigate(`/edituser/${item.sno}`)}>
                        <EditIcon/>
                    </div>
                    <div style={{marginLeft:10}} onClick={() => handledelete(item.sno)}>
                        <DeleteForeverIcon/>
                    </div>
                   </TableCell>
        </TableRow>
        )))
      }
       </TableBody>
   </Table>

  </TableContainer>
  {
                data.length > 0 && (
                    <TablePagination
                        rowsPerPageOptions={[ 10, 50, 100]}
                        component="div"
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                )
            }
 </Paper>
 </Box>
    </div>
  )
}
