import axios from 'axios'
import React, { useState,useEffect } from 'react'
import Button from '@mui/material/Button';
import { useNavigate, useParams } from 'react-router-dom';

export default function Editebot() {

    const [type, setType] = useState('')
    const [message, setMessage] = useState('')
    const [footermessage, setFootermessage] = useState('')
    const [tablename, setTablename] = useState('')
    const [templatename, setTemplatename] = useState('')
    const [file, setFile] = useState()

    const navigate =  useNavigate()
    
    

    const {id} = useParams()

    useEffect(() => {
        axios.post('https://chatbotapi.emedha.in/ebot/emedha/'+id)
        .then(res =>{
         console.log(res.data);
         setType(res.data.data.type)
         setMessage(res.data.data.message)
         setFootermessage(res.data.data.footer_message)
         setTablename(res.data.data.table_name)
         setTemplatename(res.data.data.template_name)
         setFile(res.data.data.header_image)
          
        })
        .catch(err =>{
          console.error('error',err)
        })
      },[id])

      
    const usersubmit = (e) => {
      e.preventDefault();
        axios.post('https://chatbotapi.emedha.in/ebot/emedha/edit/ebot-user',{type,message,footer_message:footermessage,table_name:tablename,template_name:templatename,header_image:file,id})
        .then(res => {
          console.log(res.data);
          alert('user updated')
          navigate('/ebot')
          })
          .catch (err => {
                console.error('Error response data:', err.response.data);
          })
    }


  return (
    <>
    <div className="container">
        <h1>Edit </h1>
    </div>
     <form onSubmit={usersubmit}>
            <div className='form d-flex gap-4'>
                
            <div className='formLeft d-flex flex-column gap-2'>
                
            <div className='d-flex flex-column' style={{marginBottom:10}}>           
            <label style={{margin:'0px 0px 14px',display:'block'}}>
                Type:
            </label>
            <input
                 type="text"
                value={type}
                onChange={(e) => setType(e.target.value)}
                 style={{ width: "30vw", outline: "none",padding:'0.45rem 0.75rem ' }}
                 placeholder="Enter First Name"
               />
                
            </div>
            
            <div className='d-flex flex-column'>           
            <label style={{margin:'0px 0px 14px',display:'block'}}>
                Message
            </label>
            <input
                 type="text"
                
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                style={{ width: "30vw", outline: "none",padding:'0.45rem 0.75rem ' }}
               />
                
            </div>
            <div className='d-flex flex-column'>           
            <label style={{margin:'0px 0px 14px',display:'block'}}>
                Footer Message
            </label>
            <input
                 type="text"
               
                value={footermessage}
                onChange={(e) => setFootermessage(e.target.value)}
                style={{ width: "30vw", outline: "none",padding:'0.45rem 0.75rem ' }}
               />
                
            </div>
            <div className='d-flex flex-column'>           
            <label style={{margin:'0px 0px 14px',display:'block'}}>
                Table Name
            </label>
            <input
                 type="text"
               
                value={tablename}
                onChange={(e) => setTablename(e.target.value)}
                style={{ width: "30vw", outline: "none",padding:'0.45rem 0.75rem ' }}
               />
                
            </div>
            <div className='d-flex flex-column'>           
            <label style={{margin:'0px 0px 14px',display:'block'}}>
                Template Name
            </label>
            <input
                 type="text"
             
                value={templatename}
                onChange={(e) => setTemplatename(e.target.value)}
                style={{ width: "30vw", outline: "none",padding:'0.45rem 0.75rem ' }}
               />
                
            </div>

            <div className='d-flex flex-column mb-5'>           
           
            <input
                 type="file"
                onChange={(e) => setFile(e.target.files[0])}
               
                style={{ width: "30vw", outline: "none",padding:'0.45rem 0.75rem ' }}
               />
                
            </div>
           
            </div>
            
            </div>
            <Button variant="contained" color='success' className='my-5 px-5 py-2' style={{marginTop:"10px"}} type='submit'>
              update
           </Button>
        </form>
    </>
  )
}
