import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

function createData(id, name, calories, fat, carbs, protein) {
  return {
    id,
    name,
    calories,
    fat,
    carbs,
    protein,
  };
}

const rows = [
 
];


export default function TemplateInfo() {
  const [page, setPage] = React.useState(0);
  
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

   const navigate =  useNavigate()

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(+event.target.value));
    setPage(0);
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    axios.post('https://emedha.com/echatbot/get-template-status.php?limit=10')
    .then((res => {
      console.log("temp MESSAGES", res.data.data);
      setData(res.data.data)
    }))
    .catch((err => {
      console.error(err)
    }))
  },[])

  const handledelete = (id) => {
    axios.post(`https://chatbotapi.emedha.in/wapp/emedha/del/${id}`)
    .then((res => {
      console.log(res.data)
      alert('user deleted')
      setData(prevData => prevData.filter((item => item.id != id)))
    }))
    .catch((err) => {
      console.error('Error',err)
    })
  }

  const handlereset = () => {
    axios.post('https://chatbotapi.emedha.in/wapp/emedha/wapp-reset')
    .then((res => {
      console.log(res.data)
      alert('Successfully  reseted')
   
    }))
    .catch((err) => {
      console.error('Error',err)
    })
  }

  const [viewdata, setViewData] = React.useState({});

  const handlesubmit = (name) => {
    handleClickOpen()
    axios.post(`https://emedha.com/echatbot/get-template.php?name=${name}`)
    .then(res => {
        if (res.data.data && res.data.data.length > 0) {
            console.log("ddd", res.data.data[0]);
            setViewData(res.data.data[0]);
        } else {
            console.error("No data found.");
        }
    })
    .catch(error => {
        console.error("Error fetching data:", error);
    });
}

const [open, setOpen] = React.useState(false);

const handleClickOpen = () => {
  setOpen(true);
};

const handleClose = () => {
  setOpen(false);
};

  return (
    <div>
      <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
          
          <TableContainer>
      
      {/* <div style={{margin:30}} >
      <Button variant="outlined">Total Records : {data.length}</Button>
      <Button variant="outlined" endIcon={<AddIcon />} style={{marginLeft:20}} onClick={() => {navigate('/wappadd')}}>
        ADD
      </Button>
      <Button variant="outlined" style={{marginLeft:20}} onClick={handlereset}>
        RESET
      </Button>
      </div> */}
      
   <Table >
      
       <TableHead sx={{ backgroundColor: 'lightgray' }}>
         <TableRow>
           <TableCell
             
             style={{ top: 57, minWidth: 30, fontWeight: 'bold', fontSize: 12 }}
           >
             Id
           </TableCell>
           <TableCell
            
             style={{ top: 57, minWidth: 30, fontWeight: 'bold', fontSize: 12 }}
           >
             Name
           </TableCell>
           <TableCell

             
             style={{ top: 57, minWidth: 87, fontWeight: 'bold', fontSize: 12 }}
           >
             Status
           </TableCell>
           <TableCell

            
             style={{ top: 57, minWidth: 87, fontWeight: 'bold', fontSize: 12 }}
           >
             Action
           </TableCell>
         </TableRow>
       </TableHead>
       <TableBody>        
      {
        data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item => (
          <TableRow key={item.sno} tabIndex={-1} >
                 <TableCell 
                   style={{ top: 57, minWidth: 30, fontSize: 12 }}>{item.id}</TableCell>
                 <TableCell 
                   style={{ top: 57, minWidth: 30, fontSize: 12 }}>{item.name}</TableCell>
                 <TableCell 
                   style={{ top: 57, minWidth: 87, fontSize: 12 }}>{item.status}</TableCell>
                 <TableCell 
                   style={{ top: 57, minWidth: 87, fontSize: 12,display:'flex'}}>
                    {/* <div onClick={() => navigate(`/edituser/${item.sno}`)}>
                        <EditIcon/>
                    </div> */}
                    <div style={{marginLeft:10}} onClick={() => handlesubmit(item.name)}>
                        <RemoveRedEyeIcon/>
                    </div>
                   </TableCell>
        </TableRow>
        )))
      } 
       </TableBody>
   </Table>

  </TableContainer>
  {
                data.length > 0 && (
                    <TablePagination
                        rowsPerPageOptions={[ 10, 50, 100]}
                        component="div"
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                )
            }
 </Paper>

 <React.Fragment>
      <Dialog
        open={open}
        maxWidth='lg'
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
          <TableContainer>

      
   
      
<Table >
   
    <TableHead sx={{ backgroundColor: 'lightgray' }}>
      <TableRow>
       
        <TableCell
          align={"center"}
          style={{ top: 57, minWidth: 30, fontWeight: 'bold', fontSize: 12 }}
        >
          Id
        </TableCell>
        <TableCell
          align={"center"}
          style={{ top: 57, minWidth: 30, fontWeight: 'bold', fontSize: 12 }}
        >
          Name
        </TableCell>
        <TableCell
          align={"center"}
          style={{ top: 57, minWidth: 30, fontWeight: 'bold', fontSize: 12 }}
        >
          Type
        </TableCell>
        <TableCell
          align={"center"}
          style={{ top: 57, minWidth: 30, fontWeight: 'bold', fontSize: 12 }}
        >
          text
        </TableCell>
        <TableCell
          align={"center"}
          style={{ top: 57, minWidth: 30, fontWeight: 'bold', fontSize: 12 }}
        >
          Category
        </TableCell>
        <TableCell
          align={"center"}
          style={{ top: 57, minWidth: 30, fontWeight: 'bold', fontSize: 12 }}
        >
          Language
        </TableCell>
        <TableCell

          align={"center"}
          style={{ top: 57, minWidth: 87, fontWeight: 'bold', fontSize: 12 }}
        >
          Status
        </TableCell>
        <TableCell

          align={"center"}
          style={{ top: 57, minWidth: 87, fontWeight: 'bold', fontSize: 12 }}
        >
          Body Text
        </TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
        <TableRow tabIndex={-1} key={viewdata.id}>
          <TableCell align="center" style={{ minWidth: 30, fontSize: 12 }}>
            {viewdata.id}
          </TableCell>
          <TableCell align="center" style={{ minWidth: 30, fontSize: 12 }}>
            {viewdata.name}
          </TableCell>
          <TableCell align="center" style={{ minWidth: 30, fontSize: 12 }}>
            {viewdata.components?.[0]?.type}
          </TableCell>
          <TableCell align="center" style={{ minWidth: 87, fontSize: 12 }}>
            {viewdata.components?.[0]?.text}
          </TableCell>
          <TableCell align="center" style={{ minWidth: 30, fontSize: 12 }}>
            {viewdata.category}
          </TableCell>
          <TableCell align="center" style={{ minWidth: 30, fontSize: 12 }}>
            {viewdata.language}
          </TableCell>
          <TableCell align="center" style={{ minWidth: 87, fontSize: 12 }}>
            <button
              style={{
                height: 20,
                color: 'white',
                borderRadius: '0.4rem',
                border: 'none',
                paddingLeft: 10,
                paddingRight: 10,
                backgroundColor: 'green',
              }}
            >
              {viewdata.status}
            </button>
          </TableCell>
          <TableCell align="center" style={{ minWidth: 30, fontSize: 12 }}>
            {viewdata.components?.[0]?.example?.body_text?.[0]?.join(', ')}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
    </TableContainer>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
 </Box>
    </div>
  )
}
